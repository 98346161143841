body {
  margin-top: 1rem;
  color: #fff;
  background-color: #600;
}

h1 {
  font-family: 'Sniglet', sans-serif;
  font-size: 60px;
}

a:link,
a:active,
a:visited {
  color: #fff;
}

footer {
  border-top: 1px solid #dee2e6;
}
